import styled from "styled-components";

export const CustomSelectStyles = styled.div`
  width: 100%;
  display: grid;
  position: relative;
  
  .MuiSelect-select {
    padding: 12px !important;
  }
  
  .MuiSelect-iconOpen {
    transform: rotate(180deg);
  }
  
  .errorMessage {
    position: absolute;
    bottom: -4px;
  }
  
  .formErrorContainer {
    width: 100%;
    height: 12px;
  }
`;
