import styled from "styled-components";

export const UploadButtonStyles = styled.div`
  display: grid;
  gap: 4px;
  
  .buttonWrapper {
    display: flex;
    gap: 8px;
    align-items: center;
  }
`;
