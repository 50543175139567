import styled from "styled-components";

export const TextEditorStyles = styled.div`
  &.error {
    .tox-editor-container {
      border: 1px solid red;
    }
  }
  
  .MuiTypography-root {
    display: block;
    padding-top: 2px;
  }
  
  .tox-tinymce {
    border: 1px solid #E5E5E5;
    border-radius: 4px;
  }

  .tox:not(.tox-tinymce-inline) .tox-editor-header {
    box-shadow: none;
    border-bottom: 1px solid #E5E5E5;
  }
`;
