import React, {
  useState, useRef, forwardRef, useEffect,
} from "react";
import PropTypes from "prop-types";
import MDInputRoot from "components/MDInput/MDInputRoot";
import MDTypography from "../MDTypography";
import Tooltip from "@mui/material/Tooltip";
import { Icon } from "@mui/material";
import { MDInputStyles } from "./MDInputStyles";

const MDInput = forwardRef(
  (
    {
      error,
      success,
      disabled,
      errorMessage,
      tooltip,
      inputExtraStyles,
      extraContainerStyles,
      test,
      showError = false,
      onChange,
      value,
      ...rest
    },
    ref,
  ) => {
    const [inputValue, setInputValue] = useState(value);
    const timeoutRef = useRef(null);

    const handleInputChange = (event) => {
      const value = event.target.value;
      setInputValue(value);

      clearTimeout(timeoutRef.current);

      timeoutRef.current = setTimeout(() => {
        if (onChange) {
          onChange(event);
        }
      }, 300);
    };

    useEffect(() => {
      setInputValue(value);
    }, [value]);

    return (
      <MDInputStyles style={extraContainerStyles} className="inputWrapper">
        <div className="inputWrapper">
          <MDInputRoot
            {...rest}
            ref={ref}
            ownerState={{ error, success, disabled }}
            sx={inputExtraStyles}
            value={inputValue}
            onChange={handleInputChange}
          />
          {showError && (
            <div className="formErrorContainer">
              {error && errorMessage && (
                <MDTypography variant="caption" fontWeight="regular" color="error">
                  {errorMessage}
                </MDTypography>
              )}
            </div>
          )}
        </div>
        {tooltip && (
          <Tooltip title={tooltip} arrow>
            <Icon>question_mark</Icon>
          </Tooltip>
        )}
      </MDInputStyles>
    );
  },
);

MDInput.defaultProps = {
  error: false,
  success: false,
  disabled: false,
};

MDInput.propTypes = {
  error: PropTypes.bool,
  success: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

export default React.memo(MDInput);
