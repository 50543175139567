import { FormControlLabel, FormGroup } from "@mui/material";
import React from "react";
import Checkbox from "@mui/material/Checkbox";

function CheckBox({ checked, label, onChange }) {
  return (
    <div>
      <FormGroup>
        <FormControlLabel control={<Checkbox checked={checked} onChange={onChange} />} label={label} />
      </FormGroup>
    </div>
  );
}

export default CheckBox;
