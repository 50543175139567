import styled from "styled-components";

export const PageMetaStyles = styled.div`
  .loaderWrapper {
    position: absolute;
    
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-grow: 1;
  }
  
  .content {
    position: relative;
    display: flex;
    height: 100%;
    flex-grow: 1;
    padding: 30px 1rem 0;
  }

  .formContent {
    display: grid;
    gap: 24px;
    height: fit-content;
  }
  
  .fieldsWrapper {
    display: grid;
    gap: 12px;
    margin-bottom: 24px;
  }

  .fields {
    display: grid;
    grid-template-columns: 400px 400px;
    gap: 24px 6px;
  }
`;
