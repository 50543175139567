// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Data
import DataTable from "../../components/table";
import React, { useEffect, useMemo, useState } from "react";
import { Button } from "@mui/material";
import Api from "../../api/api";
import { HashLoader } from "react-spinners";
import { format } from "date-fns";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useIsFirstRender } from "usehooks-ts";
import workWithResponse from "../../functions/workWithResponse";
import { ResumesStyles } from "./ResumesStyles";

function ResumesTable() {
  const { pageNumber } = useParams();
  const location = useLocation();
  const { vacancyID } = location.state || {};
  const navigate = useNavigate();
  const isFirst = useIsFirstRender();

  const [pagesResumes, setPagesResumes] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [resumesCount, setResumesCount] = useState(null);
  const [activePage, setActivePage] = useState(1);

  const sortedAccessors = [];

  useEffect(() => {
    console.log(vacancyID);
    setActivePage(+pageNumber);
  }, []);

  async function getVacancies() {
    const { data: pagesVacancy, count } = await workWithResponse(() => Api.getResumes(vacancyID, (activePage - 1) * 10, 10)).then((res) => {
      if (!res.error && res.data) {
        return res.data;
      }
    });

    if (count !== resumesCount) {
      setResumesCount(count);
    }

    setPagesResumes(pagesVacancy);
  }

  useEffect(() => {
    if (!isFirst) {
      setIsLoading(true);
      if (activePage !== pageNumber) {
        navigate(`/resumes/${activePage}`, { state: { vacancyID } });
      }

      getVacancies().then(() => setIsLoading(false));
    }
  }, [activePage]);

  useEffect(() => {
    async function getInitialData() {
      const pagesResumes = await workWithResponse(() => Api.getResumes(vacancyID)).then((res) => {
        console.log(res);
        if (!res.error && res.data) {
          setResumesCount(res.data.count);

          return res.data.data;
        }
      });

      setPagesResumes(pagesResumes);
    }

    getInitialData().then(() => setIsLoading(false));
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        width: "30%",
        align: "left",
      },
      { Header: "Email", accessor: "email", align: "left" },
      { Header: "Phone", accessor: "phone", align: "left" },
      { Header: "CV", accessor: "cvUrl", align: "center" },
      { Header: "Create at", accessor: "createdAt", align: "center" },
    ],
    [],
  );

  const rows = useMemo(() => {
    if (pagesResumes) {
      return pagesResumes.map((page) => ({
        name: (
          <MDTypography component="span" variant="button" color="text" fontWeight="medium">
            {`${page.name} ${page.surname}`}
          </MDTypography>
        ),
        email: (
          <MDTypography component="span" href="#" variant="button" color="text" fontWeight="medium">
            {page.email}
          </MDTypography>
        ),
        phone: (
          <MDTypography component="span" href="#" variant="button" color="text" fontWeight="medium">
            {page.phone}
          </MDTypography>
        ),
        cvUrl: (
          <MDTypography component="span" href="#" variant="button" color="text" fontWeight="medium">
            <a href={page.cvUrl} target="_blank" rel="noreferrer">link</a>
          </MDTypography>
        ),
        createdAt: format(new Date(page.createdAt), "kk:mm:ss dd.MM.yyyy"),
      }));
    }
  }, [pagesResumes]);

  return (
    <ResumesStyles>
      <DashboardLayout>
        <DashboardNavbar propsRoute={[""]} propsTitle="Pages vacancies" />
        <div className="content">
          {!isLoading && pagesResumes ? (
            <div className="tableWrapper">
              <Grid container spacing={6}>
                <Grid item xs={12}>
                  <Card>
                    <MDBox
                      mx={2}
                      mt={-3}
                      py={3}
                      px={2}
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="info"
                    >
                      <div className="tableHeader">
                        <MDTypography variant="h6" color="white">
                          Pages vacancies
                        </MDTypography>

                        <div className="tableHeaderButtonsWrapper">
                          <Link to="/vacancy/create">
                            <Button variant="contained" color="info">
                              <MDTypography variant="button" color="info" fontWeight="bold">
                                Add new
                              </MDTypography>
                            </Button>
                          </Link>
                        </div>
                      </div>
                    </MDBox>

                    <MDBox pt={3}>
                      {resumesCount > 0 ? (
                        <DataTable
                          table={{ columns, rows }}
                          isSorted={false}
                          entriesPerPage
                          showTotalEntries
                          noEndBorder
                          sortedAccessors={sortedAccessors}
                          pagination
                          activePage={activePage}
                          pagesCount={resumesCount}
                          setActivePage={setActivePage}
                          countOnPage={10}
                        />
                      ) : (
                        <MDBox
                          variant=""
                          fontWeight="regular"
                          color="error"
                          sx={{ textAlign: "center", fontSize: "18px", marginBottom: "12px" }}
                        >
                          No pages vacancies were found for your request
                        </MDBox>
                      )}
                    </MDBox>
                  </Card>
                </Grid>
              </Grid>
            </div>
          ) : (
            <div className="loaderWrapper">
              <HashLoader color="#49a3f1" />
            </div>
          )}
        </div>
      </DashboardLayout>
    </ResumesStyles>
  );
}

export default ResumesTable;
