import Icon from "@mui/material/Icon";
import PagesPage from "./pages/pagesPage/PagesPage";
import PagesMetaTable from "./pages/pagesMeta/PagesMetaTable";
import PagesVacancyTable from "pages/vacancy/PagesVacancyTable";
import ResumesTable from "pages/resumes/ResumesTable";

const routes = [
  {
    type: "collapse",
    name: "Pages",
    key: "pages",
    path: "/pages/1",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/pages/:pageNumber",
    component: <PagesPage />,
  },
  {
    type: "collapse",
    name: "Meta",
    key: "meta",
    path: "/meta/1",
    icon: <Icon fontSize="small">manage_search</Icon>,
    route: "/meta/:pageNumber",
    component: <PagesMetaTable />,
  },
  {
    type: "collapse",
    name: "Vacancy",
    key: "vacancy",
    path: "/vacancy/1",
    icon: <Icon fontSize="small">work</Icon>,
    route: "/vacancy/:pageNumber",
    component: <PagesVacancyTable />,
  },
  {
    type: "collapse",
    name: "Resumes",
    key: "resumes",
    path: "/resumes/1",
    icon: <Icon fontSize="small">group_add</Icon>,
    route: "/resumes/:pageNumber",
    component: <ResumesTable />,
  },
];

export default routes;
