import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    standard: {
      main: "#1976d2",
      darker: "#1565c0",
    },
  },
});
