import React, { useMemo, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { CustomSelectStyles } from "./CustomSelectStyles";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Icon, InputAdornment, ListSubheader, OutlinedInput,
} from "@mui/material";
import MDTypography from "../../MDTypography";
import MDInput from "../../MDInput";

function CustomSelect({
  name, value, valueKey, optionTextKey, options, label, onChange, placeholder, error, errorMessage, withSearch = true, width = 400, canSelectEmptyOption = false, showError = false,
}) {
  const [searchParams, setSearchParams] = useState("");

  const filteredOptions = useMemo(() => {
    if (withSearch && searchParams.length > 0) {
      return options.filter((option) => option[optionTextKey].toLowerCase().includes(searchParams.toLowerCase()));
    }

    return options;
  }, [searchParams]);

  return (
    <CustomSelectStyles>
      {label && (
        <MDTypography variant="caption" fontWeight="regular" color="text">
          {label}
        </MDTypography>
      )}

      <FormControl sx={{ width }} size="medium">
        <Select
          size="normal"
          // error={error}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          labelId={name}
          value={value}
          classes={{ select: "customSelect" }}
          label={!placeholder ? label : undefined}
          sx={{
            "& .MuiSelect-select": {
              paddingTop: "12px !important",
              paddingBottom: "12px !important",
            },
          }}
          /* eslint-disable-next-line react/no-unstable-nested-components */
          IconComponent={(_props) => {
            const rotate = _props.className.toString().includes("iconOpen");
            return (
              <div
                style={{
                  position: "absolute",
                  cursor: "pointer",
                  pointerEvents: "none",
                  right: 3,
                  height: "24px",
                  width: "24px",
                  transform: rotate ? "rotate(180deg)" : "none",
                }}
              >
                <ArrowDropDownIcon sx={{ width: "24px", height: "24px" }} />
              </div>
            );
          }}
        >
          {withSearch && (
            <ListSubheader sx={{ padding: "0 0 8px 0" }}>
              <MDInput
                size="small"
                // Autofocus on textfield
                autoFocus
                placeholder="Type to search..."
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon>search_icon</Icon>
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => setSearchParams(e.target.value)}
                onClick={(e) => e.stopPropagation()}
                onKeyDown={(e) => {
                  if (e.key !== "Escape") {
                    // Prevents autoselecting item while typing (default Select behaviour)
                    e.stopPropagation();
                  }
                }}
              />
            </ListSubheader>
          )}
          {placeholder && (
            <MenuItem value="" onClick={() => onChange("")} disabled={!canSelectEmptyOption}>
              <em>{placeholder}</em>
            </MenuItem>
          )}

          {filteredOptions.map((option, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <MenuItem key={index} onClick={() => onChange(option[valueKey])} value={option[valueKey]}>
              {option[optionTextKey]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {showError && (
        <div className="formErrorContainer">
          {error && errorMessage && (
            <MDTypography variant="caption" fontWeight="regular" color="error" classes={{ root: "errorMessage" }}>
              {errorMessage}
            </MDTypography>
          )}
        </div>
      )}
    </CustomSelectStyles>
  );
}

export default CustomSelect;
