import React from "react";
import MDButton from "../../MDButton";
import MDTypography from "../../MDTypography";
import { UploadButtonStyles } from "./UploadButtonStyles";

function UploadButton({
  onChangeFile, isLoading, label, text, error, errorText, isTextALink, accept,
}) {
  return (
    <UploadButtonStyles>
      <MDTypography component="p" variant="caption" color="text" fontWeight="bold">
        {label}
      </MDTypography>

      <div className="buttonWrapper">
        <MDButton color={error ? "error" : "info"} variant="contained" component="label" disabled={isLoading}>
          {isLoading ? "Loading..." : error ? "Error" : "Upload"}
          <input hidden accept={accept || "image/*"} multiple type="file" onChange={(e) => onChangeFile(e.target.files[0])} />
        </MDButton>

        <MDTypography component={!isTextALink ? "p" : "a"} variant="caption" color="text" fontWeight="medium" href={text} target="_blank">
          {text || "No image"}
        </MDTypography>
      </div>

      {error && (
        <MDTypography variant="caption" fontWeight="regular" color="error">
          {errorText || "An error occurred while saving data"}
        </MDTypography>
      )}

    </UploadButtonStyles>
  );
}

export default UploadButton;
