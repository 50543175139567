import { useEffect, useMemo, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-table components
import {
  useGlobalFilter, usePagination, useSortBy, useTable,
} from "react-table";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DataTableHeadCell from "./DataTableHeadCell";
import DataTableBodyCell from "./DataTableBodyCell";
import CustomPagination from "../pagination/CustomPagination";

function DataTable({
  activePage,
  pagesCount,
  setActivePage,
  countOnPage,
  showTotalEntries,
  table,
  isSorted,
  noEndBorder,
  sortedAccessors,
  setQuery,
}) {
  const columns = useMemo(() => table.columns, [table]);
  const data = useMemo(() => table.rows, [table]);
  const [sortedObject, setSortedObject] = useState({});

  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: 0, pageSize: countOnPage } },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const handleSortToggle = (item) => {
    const updatedSettings = { ...sortedObject };

    for (const key in updatedSettings) {
      if (key === item) {
        if (!updatedSettings[key].sorted) {
          updatedSettings[key].sorted = true;
          updatedSettings[key].dir = "asc";
        } else if (updatedSettings[key].dir === "asc") {
          updatedSettings[key].dir = "desc";
        } else {
          updatedSettings[key].sorted = false;
          updatedSettings[key].dir = "none";
        }
      } else {
        updatedSettings[key].sorted = false;
        updatedSettings[key].dir = "none";
      }
    }

    setSortedObject(updatedSettings);
  };

  useEffect(() => {
    if (setQuery && isSorted) {
      const sortedItem = Object.keys(sortedObject).find((key) => sortedObject[key].sorted);
      let queryStr = "";

      if (sortedItem) {
        queryStr += `&orderBy=${sortedObject[sortedItem].name}`;
        queryStr += `&orderDir=${sortedObject[sortedItem].dir}`;

        setQuery(queryStr);
      }
    }
  }, [sortedObject]);

  useEffect(() => {
    const reduceSorted = sortedAccessors.reduce((acc, accessor) => {
      acc[accessor] = {
        sorted: false,
        dir: "none",
        name: accessor,
      };
      return acc;
    }, {});

    setSortedObject(reduceSorted);
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
  } = tableInstance;

  return (
    <TableContainer sx={{ boxShadow: "none" }}>
      <Table {...getTableProps()}>
        <MDBox component="thead">
          {headerGroups.map((headerGroup) => (
            <TableRow key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <DataTableHeadCell
                  key={column.id}
                  width={column.width ? column.width : "auto"}
                  align={column.align ? column.align : "left"}
                  sorted={sortedAccessors.includes(column.id)}
                  column={column}
                  sortedObject={sortedObject}
                  onClick={() => handleSortToggle(column.id)}
                >
                  {column.render("Header")}
                </DataTableHeadCell>
              ))}
            </TableRow>
          ))}
        </MDBox>
        <TableBody {...getTableBodyProps()}>
          {page.map((row, key) => {
            prepareRow(row);
            return (
              <TableRow {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <DataTableBodyCell
                    noBorder={noEndBorder && rows.length - 1 === key}
                    align={cell.column.align ? cell.column.align : "left"}
                    {...cell.getCellProps()}
                  >
                    {cell.render("Cell")}
                  </DataTableBodyCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      <MDBox
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
      >
        {pagesCount && pagesCount > 0 && setActivePage && activePage && (
          <CustomPagination
            activeIndex={activePage}
            total={pagesCount}
            setActivePage={setActivePage}
            countOnPage={countOnPage}
          />
        )}
      </MDBox>
    </TableContainer>
  );
}

// Setting default values for the props of DataTable
DataTable.defaultProps = {
  entriesPerPage: { defaultValue: 10, entries: [5, 10, 15, 20, 25] },
  canSearch: false,
  showTotalEntries: true,
  isSorted: true,
  noEndBorder: false,
};

// Typechecking props for the DataTable
DataTable.propTypes = {
  entriesPerPage: PropTypes.oneOfType([
    PropTypes.shape({
      defaultValue: PropTypes.number,
      entries: PropTypes.arrayOf(PropTypes.number),
    }),
    PropTypes.bool,
  ]),
  canSearch: PropTypes.bool,
  showTotalEntries: PropTypes.bool,
  table: PropTypes.objectOf(PropTypes.array).isRequired,
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
};

export default DataTable;
