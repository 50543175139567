import { useRef, useState } from "react";

import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import BasicLayout from "layouts/authentication/components/BasicLayout";

import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { useFormik } from "formik";
import { initialValues, validationSchema } from "./additionalSetting";
import { useAppDispatch } from "../../state/hooks";
import { loginUser } from "../../state/slices/userSlice";

function Login() {
  const forTriedToSubmitRef = useRef(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [loginError, setLoginError] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const dispatch = useAppDispatch();

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    errors,
    touched,
    resetForm,
    setValues,
    setFieldValue,
  } = useFormik({
    validationSchema,
    validateOnChange: forTriedToSubmitRef.current,
    validateOnBlur: false,
    initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      dispatch(loginUser({ userData: values, errorHandler: () => setLoginError(true) }));
    },
  });

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white">
            Sign in
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              {loginError && (
                <MDBox variant="" fontWeight="regular" color="error" sx={{ textAlign: "center", fontSize: "14px", marginBottom: "12px" }}>
                  The entered email or password is incorrect
                </MDBox>
              )}

              <MDInput
                type="email"
                label="Email"
                fullWidth
                showError
                error={errors.email && touched.email}
                success={values.email.length > 0 && values.email.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/)}
                errorMessage={errors.email}
                value={values.email}
                onChange={(e) => setFieldValue("email", e.target.value)}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                showError
                fullWidth
                error={errors.password && touched.password}
                errorMessage={errors.password}
                success={values.password.length > 6}
                value={values.password}
                onChange={(e) => setFieldValue("password", e.target.value)}
              />
            </MDBox>
            {/* <MDBox display="flex" alignItems="center" ml={-1}> */}
            {/*  <Switch checked={rememberMe} onChange={handleSetRememberMe} /> */}
            {/*  <MDTypography */}
            {/*    variant="button" */}
            {/*    fontWeight="regular" */}
            {/*    color="text" */}
            {/*    onClick={handleSetRememberMe} */}
            {/*    sx={{ cursor: "pointer", userSelect: "none", ml: -1 }} */}
            {/*  > */}
            {/*    &nbsp;&nbsp;Remember me */}
            {/*  </MDTypography> */}
            {/* </MDBox> */}
            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                onClick={() => {
                  forTriedToSubmitRef.current = true;
                  handleSubmit();
                }}
              >
                sign in
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Login;
