import React, { useEffect, useState } from "react";
import { PageFilterStyles } from "./styles";
import MDInput from "components/MDInput";
import { useDebounce } from "hooks/useDebounce";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

export const PageFilter = ({ query, setQuery }) => {
  const [name, setName] = useState("");
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();

  const debouncedName = useDebounce(name, 500);

  useEffect(() => {
    let queryStr = "";
    queryStr += debouncedName.length ? `&name=${debouncedName}` : "";

    setQuery(queryStr);
  }, [debouncedName]);

  return (
    <PageFilterStyles>
      <h1>Search By</h1>
      <div className="filter-row">
        <MDInput
          name="name"
          type="text"
          label="Name"
          showError
          value={debouncedName}
          onChange={(e) => setName(e.target.value)}
          inputExtraStyles={{ width: "100%" }}
        />
      </div>
      {/* <div className="filter-row">
        <Calendar
          onChange={setDateFrom}
          value={dateFrom}
          minDetail="year"
          minDate={new Date(2022, 4, 1)}
          maxDate={new Date()}
          locale="ua"
        />
        <Calendar
          onChange={setDateFrom}
          value={dateTo}
          minDetail="year"
          minDate={dateFrom}
          maxDate={new Date()}
          locale="ua"
        />
      </div> */}
    </PageFilterStyles>
  );
};
