import styled from "styled-components";

export const MDInputStyles = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  
  .inputWrapper {
    position: relative;
    
    display: grid;
    flex-grow: 1;
    
    .MuiTypography-root {
      position: absolute;
      bottom: -4px;
    }
  }
  
  .formErrorContainer {
    width: 100%;
    height: 12px;
  }
`;
