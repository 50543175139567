export function mapFromFileToFormData(file, width, height) {
  const formData = new FormData();

  formData.append("file", file);

  if (width) {
    formData.append("width", width.toString());
  }

  if (height) {
    formData.append("height", height.toString());
  }

  return formData;
}
