import styled from "styled-components";

export const PageVacancyStyles = styled.div`
  .loaderWrapper {
    position: absolute;

    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-grow: 1;
  }

  form {
    width: 100%;
    max-width: 800px;
  }

  .content {
    position: relative;
    display: flex;
    height: 100%;
    flex-grow: 1;
    padding: 30px 1rem 0;
  }

  .formContent {
    display: grid;
    gap: 24px;
  }

  .formFields {
    display: grid;
    gap: 40px;
    margin-bottom: 24px;
  }

  .fields {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 24px;
    column-gap: 6px;
  }

  .submitButtonContainer {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: fit-content;
  }

  .fieldsWrapper {
    display: grid;
    gap: 6px;
    row-gap: 24px;
    column-gap: 6px;
  }

  .afterSaveModalContent {
    display: grid;
    gap: 16px;
  }

  .metaFields {
    margin-bottom: 12px;
  }

  .metaFieldsWrapper {
    display: grid;
  }

  .arrayFields {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    max-width: 800px;
    flex-wrap: wrap;

    .arrayFieldsHeader {
      display: flex;
      width: 100%;
      justify-content: space-between;
      padding: 10px;
      padding: 0.625rem 1.5rem;
      background-color: #1a73e8;
      border-radius: 0.5rem;

      h3 {
        color: #fff;
      }
    }

    .arrayField {
      display: flex;
      gap: 10px;
      width: calc(50% - 10px);

      .inputWrapper {
        width: 100%;
      }
    }
  }
`;
