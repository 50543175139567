import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Api from "../../../api/api";
import workWithResponse from "../../../functions/workWithResponse";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import { HashLoader } from "react-spinners";
import {
  FieldArray, Form, Formik,
} from "formik";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import { PageVacancyStyles } from "./PageVacancyStyles";
import MDTypography from "../../../components/MDTypography";
import MDInput from "../../../components/MDInput";
import * as Yup from "yup";
import Button from "@mui/material/Button";
import { Box, Modal, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import MDButton from "../../../components/MDButton";
import CheckBox from "../../../components/form/checkBox/CheckBox";

const modalStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  display: "grid",
  gap: "24px",
};

function PageVacancy() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(null);
  const [initialValues, setInitialValues] = useState(null);
  const [submittingError, setSubmittingError] = useState(false);
  const [afterSaveModalOpen, setAfterSaveModalOpen] = useState(false);

  const formRef = useRef({ current: {} });

  const validationSchema = Yup.object({
    name: Yup.string().required("Required field"),
  });

  function onSubmit(
    values,
    { setSubmitting },
  ) {
    if (id) {
      workWithResponse(() => Api.updateVacancy(id, values)).then((res) => {
        if (res.error) {
          setSubmittingError(true);
        } else {
          setAfterSaveModalOpen(true);
        }
      });
    } else {
      workWithResponse(() => Api.createVacancy(id, values)).then((res) => {
        if (res.error) {
          setSubmittingError(true);
        } else {
          setAfterSaveModalOpen(true);
        }
      });
    }

    setSubmitting(false);
  }

  const renderForm = ({
    values,
    errors,
    touched,
    setFieldValue,
    handleChange,
  }) => {
    const a = "";

    return (
      <Form>
        <div className="formContent">
          <div className="formFieldsWrapper">
            <div className="formFields">
              <div className="fields">
                <MDInput
                  name="name"
                  type="text"
                  label="Name"
                  showError
                  value={values.name}
                  error={errors.name && touched.name}
                  errorMessage={errors.name}
                  success={values.name.length > 0}
                  onChange={handleChange}
                  inputExtraStyles={{ maxWidth: 400, width: "100%" }}
                />
                <MDInput
                  name="country"
                  type="text"
                  label="Country"
                  showError
                  value={values.country}
                  error={errors.country && touched.country}
                  errorMessage={errors.country}
                  success={values.country.length > 0}
                  onChange={handleChange}
                  inputExtraStyles={{ maxWidth: 400, width: "100%" }}
                />
              </div>
              <div className="fields">
                <MDInput
                  name="type"
                  type="text"
                  label="Type"
                  showError
                  value={values.type}
                  error={errors.type && touched.type}
                  errorMessage={errors.type}
                  success={values.type.length > 0}
                  onChange={handleChange}
                  inputExtraStyles={{ maxWidth: 400, width: "100%" }}
                />
              </div>
              <div className="fields">
                <div>
                  <CheckBox checked={values.isActive} onChange={() => setFieldValue("isActive", !values.isActive)} label="Active?" />
                </div>
              </div>

              <FieldArray
                name="offers"
                render={({ remove, push }) => (
                  <div className="arrayFields">
                    <div className="arrayFieldsHeader">
                      <h3>Offers</h3>
                      <Button
                        variant="contained"
                        color="info"
                        onClick={() => {
                          push("");
                        }}
                      >
                        <MDTypography variant="button" color="info" fontWeight="bold">
                          Add offers
                        </MDTypography>
                      </Button>
                    </div>
                    {values.offers.map((field, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <div key={`offer-${index}`} className="arrayField">
                        <MDInput
                          name={`offer-${index}`}
                          type="text"
                          label="Offer"
                          value={field}
                          success={field.length > 0}
                          onChange={(e) => setFieldValue(`offers[${index}]`, e.target.value)}
                          inputExtraStyles={{ maxWidth: 380, width: "100%" }}
                        />
                        <Button
                          variant="outlined"
                          color="error"
                          onClick={() => remove(index)}
                        >
                          <MDTypography variant="button" color="danger" fontWeight="bold">
                            Delete
                          </MDTypography>
                        </Button>
                      </div>
                    ))}
                  </div>
                )}
              />

              <FieldArray
                name="requirements"
                render={({ remove, push }) => (
                  <div className="arrayFields">
                    <div className="arrayFieldsHeader">
                      <h3>Requirements</h3>
                      <Button
                        variant="contained"
                        color="info"
                        onClick={() => {
                          push("");
                        }}
                      >
                        <MDTypography variant="button" color="info" fontWeight="bold">
                          Add requirements
                        </MDTypography>
                      </Button>
                    </div>
                    {values.requirements.map((field, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <div key={`requirement-${index}`} className="arrayField">
                        <MDInput
                          name={`requirement-${index}`}
                          type="text"
                          label="Requirement"
                          value={field}
                          success={field.length > 0}
                          onChange={(e) => setFieldValue(`requirements[${index}]`, e.target.value)}
                          inputExtraStyles={{ maxWidth: 380, width: "100%" }}
                        />
                        <Button
                          variant="outlined"
                          color="error"
                          onClick={() => remove(index)}
                        >
                          <MDTypography variant="button" color="danger" fontWeight="bold">
                            Delete
                          </MDTypography>
                        </Button>
                      </div>
                    ))}
                  </div>
                )}
              />

              <FieldArray
                name="tasks"
                render={({ remove, push }) => (
                  <div className="arrayFields">
                    <div className="arrayFieldsHeader">
                      <h3>Tasks</h3>
                      <Button
                        variant="contained"
                        color="info"
                        onClick={() => {
                          push("");
                        }}
                      >
                        <MDTypography variant="button" color="info" fontWeight="bold">
                          Add tasks
                        </MDTypography>
                      </Button>
                    </div>
                    {values.tasks.map((field, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <div key={`task-${index}`} className="arrayField">
                        <MDInput
                          name={`task-${index}`}
                          type="text"
                          label="Task"
                          value={field}
                          success={field.length > 0}
                          onChange={(e) => setFieldValue(`tasks[${index}]`, e.target.value)}
                          inputExtraStyles={{ maxWidth: 380, width: "100%" }}
                        />
                        <Button
                          variant="outlined"
                          color="error"
                          onClick={() => remove(index)}
                        >
                          <MDTypography variant="button" color="danger" fontWeight="bold">
                            Delete
                          </MDTypography>
                        </Button>
                      </div>
                    ))}
                  </div>
                )}
              />
            </div>
          </div>

          <div className="submitButtonContainer">
            <Button type="submit" variant="contained" color={submittingError ? "error" : "primary"} sx={{ width: 400, margin: "auto", color: "#FFF" }}>Submit</Button>
            {submittingError && (
              <MDTypography variant="caption" fontWeight="regular" color="error">
                An error occurred while saving data
              </MDTypography>
            )}
          </div>
        </div>
      </Form>
    );
  };

  useEffect(() => {
    setIsLoading(true);

    async function getInitialData() {
      try {
        if (id) {
          const pageVacancy = await workWithResponse(() => Api.getVacancy(id)).then((res) => {
            if (res && !res.error) {
              return res.data;
            }
          });

          setInitialValues({
            type: pageVacancy.type,
            name: pageVacancy.name,
            country: pageVacancy.country,
            isActive: pageVacancy.isActive,
            offers: pageVacancy.offers,
            requirements: pageVacancy.requirements,
            tasks: pageVacancy.tasks,
          });
        } else {
          setInitialValues(
            {
              type: "",
              name: "",
              country: "",
              isActive: "",
              offers: [],
              requirements: [],
              tasks: [],
            },
          );
        }
      } catch (e) {
        console.warn(e);
      }
    }

    getInitialData().then(() => setIsLoading(false));
  }, []);

  return (
    <PageVacancyStyles>
      <DashboardLayout>
        <DashboardNavbar propsRoute={[{ name: "Vacancies", path: "pages/1" }, ""]} propsTitle={id && initialValues && initialValues.name ? `Editing vacancy ${initialValues.name}` : "New  vacancy"} />

        {afterSaveModalOpen && (
          <Modal
            open={afterSaveModalOpen}
            onClose={() => setAfterSaveModalOpen(null)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={modalStyles}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Vacancies was succefully
                {" "}
                {id ? "updated" : "created"}
              </Typography>

              <Box display="grid" sx={{ gap: "10px" }}>
                <Grid gridColumn={1}>
                  <MDButton fullWidth onClick={() => navigate("/vacancy/1")} variant="contained" color="info">
                    OK
                  </MDButton>
                </Grid>
              </Box>
            </Box>
          </Modal>
        )}

        {isLoading ? (
          <div className="loaderWrapper">
            <HashLoader color="#49a3f1" />
          </div>
        ) : (
          <div className="content">
            {initialValues && (
              <Formik
                innerRef={formRef}
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
              >
                {renderForm}
              </Formik>
            )}
          </div>
        )}
      </DashboardLayout>
    </PageVacancyStyles>
  );
}

export default PageVacancy;
