import { subDays } from "date-fns";

export const fakeTypesData = [
  { id: 1, name: "Other" },
  { id: 2, name: "Blog" },
];

export const fakeLanguages = [
  {
    id: 1,
    name: "en",
  },
  {
    id: 2,
    name: "uk",
  },
];

export const fakePagesData = [
  {
    id: 1,
    type: 1,
    lang: 1,
    updateAt: new Date(),
    createAt: subDays(new Date(), 1),
    name: "Page 1",
  },
  {
    id: 2,
    type: 2,
    lang: 1,
    updateAt: new Date(),
    createAt: subDays(new Date(), 2),
    name: "Blog Page 1",
  },
  {
    id: 3,
    type: 2,
    lang: 2,
    updateAt: new Date(),
    createAt: subDays(new Date(), 3),
    name: "Стаття 2",
  },
  {
    id: 1,
    type: 1,
    lang: 1,
    updateAt: new Date(),
    createAt: subDays(new Date(), 1),
    name: "Page 1",
  },
  {
    id: 2,
    type: 2,
    lang: 1,
    updateAt: new Date(),
    createAt: subDays(new Date(), 2),
    name: "Blog Page 1",
  },
  {
    id: 3,
    type: 2,
    lang: 2,
    updateAt: new Date(),
    createAt: subDays(new Date(), 3),
    name: "Стаття 2",
  },
  {
    id: 1,
    type: 1,
    lang: 1,
    updateAt: new Date(),
    createAt: subDays(new Date(), 1),
    name: "Page 1",
  },
  {
    id: 2,
    type: 2,
    lang: 1,
    updateAt: new Date(),
    createAt: subDays(new Date(), 2),
    name: "Blog Page 1",
  },
  {
    id: 3,
    type: 2,
    lang: 2,
    updateAt: new Date(),
    createAt: subDays(new Date(), 3),
    name: "Стаття 2",
  },
  {
    id: 3,
    type: 2,
    lang: 2,
    updateAt: new Date(),
    createAt: subDays(new Date(), 3),
    name: "Стаття 2",
  },
];

export const fakePagesMainInfo = [
  {
    id: 1,
    name: "Page 1",
  },
  {
    id: 2,
    name: "Page 2",
  },
  {
    id: 3,
    name: "Page 3",
  },
];

export const fakeMenuData = [
  {
    name: "Something",
    page: 1,
  },
  {
    name: "Something else",
    page: 2,
  },
  {
    name: "Anything",
    page: 3,
  },
];

export const fakePageData = {
  id: 3,
  type: 2,
  lang: 2,
  updateAt: new Date(),
  createAt: subDays(new Date(), 3),
  name: "Стаття 2",
  metaTitle: "Заголок",
  metaDescription: "Опис",
  ogTitle: "fake",
  ogDescription: "data",
  keywords: "one, two",
  noIndex: false,
  contentTitle: "Title",
  previewDescription: "123",
  content: "<p>asdfzxvcx<img title=\"blogImage1.webp\" src=\"https://www.imgonline.com.ua/examples/bee-on-daisy.jpg\" alt=\"\" width=\"255\" height=\"255\"></p>",
  slug: "/post-1",
  mainImage: undefined,
  coverImage: undefined,
};
