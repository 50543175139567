import { useEffect, useMemo, useState } from "react";

// react-router components
import {
  Navigate, Route, Routes, useLocation,
} from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import Configurator from "examples/Configurator";
import theme from "assets/theme";
import themeDark from "assets/theme-dark";

import rtlPlugin from "stylis-plugin-rtl";
import createCache from "@emotion/cache";
import routes from "routes";

import { setMiniSidenav, setOpenConfigurator, useMaterialUIController } from "context";

import Sidenav from "./components/Sidenav";
import Page from "./pages/page/Page";
import Login from "./pages/login/Login";
import { useAppDispatch, useAppSelector } from "./state/hooks";
import { changeUser } from "./state/slices/userSlice";
import PageMeta from "./pages/pagesMeta/pageMeta/PageMeta";
import PagesMetaTable from "./pages/pagesMeta/PagesMetaTable";
import PageVacancy from "pages/vacancy/pageVacancy/PageVacancy";
import PagesVacancyTable from "pages/vacancy/PagesVacancyTable";

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav, direction, layout, openConfigurator, sidenavColor, darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();

  const { loggedIn } = useAppSelector((state) => state.user);
  const reduxDispatch = useAppDispatch();

  useEffect(() => {
    function checkIsUserLoggedIn() {
      const status = localStorage.getItem("logged_in");

      if (status === "false" && loggedIn === true) {
        reduxDispatch(changeUser(null));
      }
    }

    if (localStorage.getItem("logged_in") === "false" && loggedIn === true) {
      reduxDispatch(changeUser(null));
    }

    window.addEventListener("logged_in", checkIsUserLoggedIn);
  }, []);

  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) => allRoutes.map((route) => {
    if (route.collapse) {
      return getRoutes(route.collapse);
    }

    if (route.route) {
      return <Route exact path={route.route} element={route.component} key={route.key} />;
    }

    return null;
  });

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <Sidenav
          color={sidenavColor}
          brandName="Admin-panel"
          routes={routes}
          onMouseEnter={handleOnMouseEnter}
          onMouseLeave={handleOnMouseLeave}
        />
      )}
      <Routes>
        {!loggedIn ? (
          <>
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<Navigate to="/login" />} />
          </>
        ) : (
          <>
            {getRoutes(routes)}
            <Route path="/pages/edit/:id" element={<Page />} />
            <Route path="/pages/create" element={<Page />} />
            <Route path="/meta" element={<PagesMetaTable />} />
            <Route path="/meta/create" element={<PageMeta />} />
            <Route path="/meta/edit/:id" element={<PageMeta />} />
            <Route path="/vacancy" element={<PagesVacancyTable />} />
            <Route path="/vacancy/create" element={<PageVacancy />} />
            <Route path="/vacancy/edit/:id" element={<PageVacancy />} />
            <Route path="*" element={<Navigate to="/pages/1" />} />
          </>
        )}
      </Routes>
    </ThemeProvider>
  );
}
