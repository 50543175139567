import Api from "../api/api";

const errors = {
  400: {
    code: 400,
    message: "Bad Request",
    details: "The request is invalid. Please ensure that all required parameters are provided correctly.",
  },
  403: {
    code: 403,
    message: "Forbidden",
    details: "You do not have sufficient permissions.",
  },
  404: {
    code: 404,
    message: "Not Found",
    details: "The requested page could not be found.",
  },
  500: {
    code: 500,
    message: "Internal Server Error",
    details: "An unexpected error occurred on the server.",
  },
};

async function workWithResponse(request) {
  const response = await request();

  if (response.statusCode >= 200 && response.statusCode < 300) {
    return {
      error: null,
      data: response.data,
    };
  }

  if (response.statusCode !== 401) {
    return {
      error: errors[response.statusCode] || { error: response.statusCode, message: "Error", details: response.data.message },
      data: null,
    };
  }

  if (response.statusCode === 401) {
    const refreshResponse = await Api.refresh();
    if (refreshResponse.statusCode >= 200 && refreshResponse.statusCode < 300) {
      const newResponse = await request();

      return {
        error: null,
        data: newResponse.data,
      };
    }

    await Api.logout();
    localStorage.setItem("logged_in", "false");
    localStorage.removeItem("access_token");
    localStorage.removeItem("access_token_expired_at");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("refresh_token_expired_at");
  }

  return null;
}

export default workWithResponse;
