import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Api from "../../../api/api";
import workWithResponse from "../../../functions/workWithResponse";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import { HashLoader } from "react-spinners";
import { useFormik } from "formik";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import { PageMetaStyles } from "./PageMetaStyles";
import MDTypography from "../../../components/MDTypography";
import MDInput from "../../../components/MDInput";
import * as Yup from "yup";
import Button from "@mui/material/Button";
import { Box, Modal, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import MDButton from "../../../components/MDButton";

const modalStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  display: "grid",
  gap: "24px",
};

function PageMeta() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [languages, setLanguages] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [submittingError, setSubmittingError] = useState(false);
  const [afterSaveModalOpen, setAfterSaveModalOpen] = useState(false);

  const {
    handleChange, handleSubmit, handleBlur, setValues, values, errors, touched, isSubmitting,
  } = useFormik({
    initialValues: null,
    onSubmit: (values) => {
      if (id) {
        workWithResponse(() => Api.updatePageMetaData(id, values)).then((res) => {
          if (res.error) {
            setSubmittingError(true);
          } else {
            setAfterSaveModalOpen(true);
          }
        });
      } else {
        workWithResponse(() => Api.createPageMetaData(id, values)).then((res) => {
          if (res.error) {
            setSubmittingError(true);
          } else {
            setAfterSaveModalOpen(true);
          }
        });
      }
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Required field"),
      slug: Yup.string().required("Required field"),
    }),
  });

  useEffect(() => {
    setIsLoading(true);

    async function getInitialData() {
      try {
        const languages = await workWithResponse(() => Api.getLanguages()).then((res) => {
          if (res && !res.error) {
            setLanguages(res.data);
            return res.data;
          }
        });

        if (id) {
          const pageMetaData = await workWithResponse(() => Api.getPageMetaData(id)).then((res) => {
            if (res && !res.error) {
              return res.data;
            }
          });

          const formValues = languages.reduce((prev, language) => {
            const pageMetaByLang = Object.values(pageMetaData.meta).find((metaByLang) => metaByLang.code === language.code);

            return (
              {
                ...prev,
                [language.code]: pageMetaByLang || {
                  code: language.code,
                  values: {
                    metaTitle: "",
                    metaDescription: "",
                    ogTitle: "",
                    ogDescription: "",
                    keywords: "",
                  },
                },
              }
            );
          }, {});

          setValues({
            meta: formValues,
            name: pageMetaData.name,
            slug: pageMetaData.slug,
          });
        } else {
          const formValues = languages.reduce((prev, language) => (
            {
              ...prev,
              [language.code]: {
                code: language.code,
                values: {
                  metaTitle: "",
                  metaDescription: "",
                  ogTitle: "",
                  ogDescription: "",
                  keywords: "",
                },
              },
            }
          ), {});

          setValues(
            {
              meta: formValues,
              name: "",
              slug: "",
            },
          );
        }
      } catch (e) {
        console.warn(e);
      }
    }

    getInitialData().then(() => setIsLoading(false));
  }, []);

  console.log(values);

  return (
    <PageMetaStyles>
      <DashboardLayout>
        <DashboardNavbar propsRoute={[{ name: "pages meta", path: "pages/1" }, ""]} propsTitle={id && values && values.name ? `Editing ${values.name} page meta` : "New page meta"} />

        {afterSaveModalOpen && (
          <Modal
            open={afterSaveModalOpen}
            onClose={() => setAfterSaveModalOpen(null)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={modalStyles}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Page meta was succefully
                {" "}
                {id ? "updated" : "created"}
              </Typography>

              <Box display="grid" sx={{ gap: "10px" }}>
                <Grid gridColumn={1}>
                  <MDButton fullWidth onClick={() => navigate("/meta/1")} variant="contained" color="info">
                    OK
                  </MDButton>
                </Grid>
              </Box>
            </Box>
          </Modal>
        )}

        {!isLoading && values ? (
          <div className="content">
            <div className="formContent">
              <div className="fields">
                <MDInput
                  name="name"
                  type="text"
                  label="Name"
                  showError
                  value={values.name}
                  error={errors.name && touched.name}
                  errorMessage={errors.name}
                  success={values.name.length > 0}
                  onChange={handleChange}
                  inputExtraStyles={{ maxWidth: 400, width: "100%" }}
                />
                <MDInput
                  name="slug"
                  type="text"
                  label="URL"
                  showError
                  error={errors.slug && touched.slug}
                  errorMessage={errors.slug}
                  success={values.slug.length > 0}
                  value={values.slug}
                  onChange={handleChange}
                  inputExtraStyles={{ maxWidth: 400, width: "100%" }}
                />
              </div>

              {Object.values(values.meta).map(({ code: languageCode, values: fieldsValues }) => {
                const language = languages.find((language) => language.code === languageCode).name;

                return (
                  <div key={language} className="fieldsWrapper">
                    <MDTypography component="span" href="#" variant="caption" color="text" fontWeight="medium">
                      {language[0].toUpperCase() + language.slice(1)}
                    </MDTypography>

                    <div className="fields">
                      <MDInput
                        name={`meta.${languageCode}.values.metaTitle`}
                        type="text"
                        label="Meta title"
                        value={values.meta[languageCode].values.metaTitle}
                        onChange={handleChange}
                        inputExtraStyles={{ maxWidth: 400, width: "100%" }}
                        multiline
                        rows={4}
                      />

                      <MDInput
                        name={`meta.${languageCode}.values.metaDescription`}
                        type="text"
                        label="Meta description"
                        value={values.meta[languageCode].values.metaDescription}
                        onChange={handleChange}
                        inputExtraStyles={{ maxWidth: 400, width: "100%" }}
                        multiline
                        rows={4}
                      />

                      <MDInput
                        name={`meta.${languageCode}.values.ogTitle`}
                        type="text"
                        label="OG title"
                        value={values.meta[languageCode].values.ogTitle}
                        onChange={handleChange}
                        inputExtraStyles={{ maxWidth: 400, width: "100%" }}
                        multiline
                        rows={4}
                      />

                      <MDInput
                        name={`meta.${languageCode}.values.ogDescription`}
                        type="text"
                        label="OG description"
                        value={values.meta[languageCode].values.ogDescription}
                        onChange={handleChange}
                        inputExtraStyles={{ maxWidth: 400, width: "100%" }}
                        multiline
                        rows={4}
                      />

                      <MDInput
                        name={`meta.${languageCode}.values.keywords`}
                        type="text"
                        label="Keywords"
                        value={values.meta[languageCode].values.keywords}
                        onChange={handleChange}
                        inputExtraStyles={{ maxWidth: 400, width: "100%" }}
                        multiline
                        rows={4}
                      />
                    </div>
                  </div>
                );
              })}

              <div className="submitButtonContainer">
                <Button onClick={handleSubmit} type="button" variant="contained" color={submittingError ? "error" : "primary"} sx={{ width: 400, margin: "auto", color: "#FFF" }}>Submit</Button>
                {submittingError && (
                  <MDTypography variant="caption" fontWeight="regular" color="error">
                    An error occurred while saving data
                  </MDTypography>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="loaderWrapper">
            <HashLoader color="#49a3f1" />
          </div>
        )}
      </DashboardLayout>
    </PageMetaStyles>
  );
}

export default PageMeta;
